<script setup lang="ts">
const props = defineProps<{
  options?: any;
}>();

type PromoBannerCustomFields = {
  custom_storefront_global_show_promobanner: boolean;
  custom_storefront_global_promo_banner_richtext: string;
  custom_storefront_global_promo_banner_link: string;
};

const salesChannel = useSessionContext().sessionContext.value?.salesChannel;
const customFields: PromoBannerCustomFields = salesChannel?.translated?.customFields as any;

const showFullBanner = ref<boolean>(process.client && (localStorage.getItem('bannerState') === 'open' || !localStorage.getItem('bannerState')) ? true : false);

function toggleFullBanner() {
  showFullBanner.value = !showFullBanner.value;
  if (showFullBanner.value) {
    localStorage.setItem('bannerState', 'open');
  } else {
    localStorage.setItem('bannerState', 'closed');
  }
}
</script>

<template>
  <div class="promobanner-wrapper fixed w-full flex justify-end right-6 lg:right-[5.25rem] top-20 lg:top-32" v-if="customFields?.custom_storefront_global_show_promobanner">
    <div class="relative w-full max-w-[calc(100%-4rem)]">
      <ClientOnly>
      <a v-if="showFullBanner" class="promo-banner absolute right-0 w-fit flex items-center bg-darkblue text-white h-10 rounded-full p-2 px-4 pr-14 text-[12px]" :href="customFields?.custom_storefront_global_promo_banner_link" target="_blank">
        <span class="html-rendered" v-html="customFields?.custom_storefront_global_promo_banner_richtext"></span>
      </a>
      <button class="promo-button rounded-full w-10 h-10 absolute right-0 z-1 flex items-center justify-center" :class="showFullBanner ? 'bg-darkblue text-white' : 'bg-white text-dark'" @click="toggleFullBanner()">
        <span class="sr-only">Promotion anzeigen</span>
        <i class="block i-carbon-close w-5 h-5" v-if="showFullBanner"></i>
        <i class="block i-custom-svg:megaphone w-5 h-5" v-else></i>
      </button>
    </ClientOnly>
    </div>
  </div>
</template>
